import React, {useState} from 'react';
import {PageHeader} from 'components/PageHeader';
import gql from 'graphql-tag';
import {TableContainer} from "shared/CommonStyles";
import {faBell} from '@fortawesome/free-solid-svg-icons';
import {
    FeedPostCategory,
    FeedPostInput,
    UpdateFeedPostInput,
    Pagination,
    useAdminFeedPostsQuery,
    useAdminMetricsQuery,
    useCreateFeedPostMutation,
    useDeleteFeedPostMutation,
    useUpdateFeedPostMutation, useSendPushAboutFeedMutation, FeedPost, useCategoriesQuery,
} from 'types/gqlReactTypings.generated.d';
import {orderBy} from 'lodash';
import {GqlQueryRender} from 'shared/gql/gqlQueryRender';
import {TableWrapper} from 'shared/tableWrapper';
import {formatBoolean, formatDate, hookStateChangeInjector} from 'shared/Utilities';
import {AdminPaginator} from './components/adminPaginator';
import {Button, Col, Row, Modal} from 'react-bootstrap';
import AppInput, {InputType} from 'components/AppInput';
import {FileDropzone} from '../../components/fileDropzone';
import {FetchResult} from "@apollo/client";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy, faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';

library.add(faEdit, faTrash);

interface IProps {
    match?: any;
}

gql`
    query AdminFeedPosts($pagination: Pagination, $category: FeedPostCategory!) {
        adminFeedPosts(category: $category, pagination: $pagination) {
            id, userId, imageUrl, category, contentSource, description, createdAt, archived, categoryId,
            likeCount, commentCount, bookmarkCount,
            user { id, fullName }
        }
    }

    mutation CreateFeedPost($input: FeedPostInput!) {
        createFeedPost(input: $input) {
            id
        }
    }
`

export const AdminFeedPosts: React.FC<IProps> = () => {
    const [pagination, setPagination] = React.useState<Pagination>();
    const query = useAdminFeedPostsQuery({
        variables: {
            pagination: {...pagination, limit: 200,},
            category: FeedPostCategory.TrendingNow
        }
    });
    const adminMetricsQuery = useAdminMetricsQuery();
    const [createFeedPostMutation] = useCreateFeedPostMutation();
    const [deleteFeedPost] = useDeleteFeedPostMutation();
    const [updateFeedPost] = useUpdateFeedPostMutation();
    const [showPushModal, setShowPushModal] = useState(false);
    const [successCreatedModal, setSuccessCreatedModal] = useState(false);
    const [pushFeedId, setPushFeedId] = useState<number>();
    const [pushDescription, setPushDescription] = useState('');
    const [sendPush] = useSendPushAboutFeedMutation();
    const {data: catData} = useCategoriesQuery();

    const inputDefaults = {
        description: '',
        contentSource: '',
        category: FeedPostCategory.TipsAndTricks,
        categoryId: undefined,
    }

    const [input, setInput] = React.useState<FeedPostInput>(inputDefaults);
    const [updateInput, setUpdateInput] = useState<UpdateFeedPostInput>({description: '', categoryId: undefined});

    const change = hookStateChangeInjector(input, setInput);
    const changeUpdate = hookStateChangeInjector(updateInput, setUpdateInput);

    const [showEditModal, setShowEditModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState<string | null>(null);


    const handleShowEditModal = (item: FeedPost) => {
        setSelectedItemId(item.id.toString());
        setUpdateInput({description: item.description, categoryId: item.categoryId});
        setShowEditModal(true);
    };

    const handleCloseEditModal = () => {
        query?.refetch();
        setSelectedItemId(null);
        setShowEditModal(false);
    };
    const handleShowCreateModal = () => {
        setInput(inputDefaults);
        setShowCreateModal(true);
    };
    const handleCloseCreateModal = () => {
        setShowCreateModal(false);
        setInput(inputDefaults);
    };

    const handleShowDeleteModal = (itemId: string) => {
        setSelectedItemId(itemId);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setSelectedItemId(null);
        setShowDeleteModal(false);
    };

    const handleDeleteItem = (id: string | null) => {
        if (id) {
            deleteFeedPost({variables: {id}}).then(() => {
                query?.refetch();
                adminMetricsQuery?.refetch();

                handleCloseDeleteModal();
            }).catch((err: Error) => {
                window.alert('Error deleting feed post');
                console.error(err);
            });
        }

        handleCloseDeleteModal();
    };

    const handleEditItem = () => {
        if (updateInput && selectedItemId) {
            updateFeedPost({
                variables: {
                    updateFeedPostId: selectedItemId,
                    input: {
                        description: updateInput.description,
                        categoryId: updateInput.categoryId,
                    },
                },
            }).then((response: FetchResult) => {
                console.log('After updateFeedPost:', response);
                if (response.data?.updateFeedPost) {
                    console.log('Feed post edited successfully', response.data.updateFeedPost);
                    query?.refetch();
                    handleCloseEditModal();
                }
            }).catch((error: Error) => {
                window.alert('Error editing feed post');
                console.error(error);
            });
        }
    };

    const copyIdToClipboard = (id: string) => {
        const textField = document.createElement('textarea');
        textField.innerText = id;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        window.alert(`The ID "${id}" has been copied to the clipboard`);
    };

    const onSubmit = () => {
        createFeedPostMutation({variables: {input}}).then((feed) => {
            query?.refetch();
            adminMetricsQuery?.refetch();
            console.log('FEED: ', feed);
            handleCloseCreateModal();
            // window.prompt('Feed post created. Send push to users about video?');
            setPushFeedId(feed.data?.createFeedPost?.id);
            setPushDescription(input.description);
            setSuccessCreatedModal(true)
        }).catch((err: Error) => {
            window.alert('Error creating feed post');
            console.error(err);
        })
    }

    const handleShowPushModal = (feed?: FeedPost) => {
        if (feed) {
            setPushFeedId(feed.id);
            setPushDescription(feed.description);
            setShowPushModal(true);
        }
    }

    const handleSendPush = () => {
        console.log('PUSH: ', pushFeedId, pushDescription);
        sendPush({
            variables: {
                id: pushFeedId,
                text: pushDescription,
            },
        })
            .then((response: FetchResult) => {
                console.log('Send result:', response);
                if (response.data?.sendPushAboutFeed) {
                    setShowPushModal(false);
                    setPushDescription('');
                    setPushFeedId(undefined);
                }
            })
            .catch((error: Error) => {
                window.alert('Error send push');
                console.error(error);
            });
    }


    return (
        <>
            <PageHeader title={`Feed Posts (${adminMetricsQuery.data?.adminMetrics.feedPostCount ?? '-'} total)`}
                        rightItem={
                            <>
                                <Button variant="primary" onClick={handleShowCreateModal}>Add Feed Post</Button>
                                <AdminPaginator searchTerm='Feed Posts' pagination={pagination}
                                                onChange={setPagination}/>
                            </>
                        }/>
            <TableContainer>
                <TableWrapper columns={[
                    'Video',
                    'ID',
                    'User (ID)',
                    'Video URL',
                    'Category',
                    'Source',
                    'Description',
                    'Stats',
                    'Is Archived?',
                    'Created At',
                    'Actions'
                ]}>
                    <GqlQueryRender query={query}>
                        {({adminFeedPosts}) => {
                            return (
                                <tbody>
                                {orderBy(adminFeedPosts, item => item.createdAt, 'desc')
                                    .map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td>
                                                    <video src={item.imageUrl} style={{width: 200, height: 200}}
                                                           controls={true}/>
                                                </td>
                                                <td>{item.id}</td>
                                                <td>
                                                    {item.user.fullName}
                                                    <FontAwesomeIcon
                                                        icon={faCopy}
                                                        className="copy-icon"
                                                        onClick={() => copyIdToClipboard(item.user.id)}
                                                        title={item.user.id}
                                                    />
                                                </td>
                                                <td><a href={item.imageUrl} target="_blank"
                                                       rel="noopener noreferrer">LINK</a></td>
                                                <td>#{item.category}</td>
                                                <td>{item.contentSource}</td>
                                                <td>{item.description}</td>
                                                <td style={{minWidth: 150}}>
                                                    {item.likeCount} likes<br/>
                                                    {item.bookmarkCount} saves<br/>
                                                    {item.commentCount} comments<br/>
                                                </td>
                                                <td>{formatBoolean(item.archived)}</td>
                                                <td>{formatDate(item.createdAt)}</td>
                                                <FontAwesomeIcon
                                                    icon="edit"
                                                    className="d-block-1"
                                                    style={{marginBottom: 10}}
                                                    onClick={() => handleShowEditModal(item as FeedPost)}
                                                />
                                                <FontAwesomeIcon
                                                    icon={faBell}
                                                    className="d-block-1"
                                                    style={{marginBottom: 10}}
                                                    onClick={() => handleShowPushModal(item as FeedPost)}
                                                />
                                                <FontAwesomeIcon
                                                    icon="trash"
                                                    className="d-block-1"
                                                    style={{marginBottom: 10}}
                                                    onClick={() => handleShowDeleteModal(item.id.toString())}
                                                />
                                            </tr>
                                        )
                                    })}
                                <Modal show={showEditModal} onHide={handleCloseEditModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edit video</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <AppInput
                                            type={InputType.TEXT_AREA}
                                            value={updateInput.description}
                                            onChange={changeUpdate('description')}
                                            label='Description*'
                                        />
                                        <AppInput type={InputType.SELECT} value={updateInput.categoryId}
                                                  onChange={(val) => changeUpdate('categoryId')(Number.parseInt(val.toString()))}
                                                  label='Drill Category'>
                                            <option value={undefined} key={0}>---</option>
                                            {catData?.categories.map(({id, title}) => {
                                                return <option value={id} key={id}>{title}</option>
                                            })}
                                        </AppInput>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseEditModal}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={handleEditItem}>
                                            Save changes
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Delete video</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        Are you sure you want to delete this video?
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseDeleteModal}>
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="danger"
                                            onClick={() => {
                                                handleDeleteItem(selectedItemId);
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                </tbody>
                            )
                        }}
                    </GqlQueryRender>
                </TableWrapper>
            </TableContainer>
            <Modal show={showCreateModal} onHide={handleCloseCreateModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Feed Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{marginBottom: 20}}>
                        <Col md={12}>
                            <AppInput type={InputType.TEXT} value={input.imageUrl} onChange={change('imageUrl')}
                                      label='Video URL*'/>
                            <FileDropzone onSuccess={file => {
                                change('imageUrl')(file);
                            }} accept={{'video/*': []}}/>
                        </Col>
                        <Col md={6}>
                            <AppInput type={InputType.SELECT} value={input.category} onChange={change('category')}
                                      label='Category*'>
                                {Object.entries(FeedPostCategory).map(([key, value]) => {
                                    if (value === FeedPostCategory.TrendingNow) {
                                        return null;
                                    }
                                    return <option value={value} key={key}>{value}</option>
                                })}
                            </AppInput>
                        </Col>
                        <Col md={6}>
                            <AppInput type={InputType.TEXT} value={input.contentSource}
                                      onChange={change('contentSource')}
                                      label='Content Source*'/>
                        </Col>
                        <Col md={12}>
                            <AppInput type={InputType.TEXT_AREA} value={input.description}
                                      onChange={change('description')}
                                      label='Description*'/>
                        </Col>
                        <Col md={12}>
                            <AppInput type={InputType.SELECT} value={input.categoryId}
                                      onChange={(val) => change('categoryId')(Number.parseInt(val.toString()))}
                                      label='Drill Category'>
                                <option value={undefined} key={0}>---</option>
                                {catData?.categories.map(({id, title}) => {
                                    return <option value={id} key={id}>{title}</option>
                                })}
                            </AppInput>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreateModal}>Close</Button>
                    <Button variant='success' onClick={onSubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>
            {/*<h4>Add New Feed Post</h4>*/}
            {/*<Row style={{marginBottom: 20}}>*/}
            {/*    <Col md={4}>*/}
            {/*        <AppInput type={InputType.TEXT} value={input.imageUrl} onChange={change('imageUrl')}*/}
            {/*                  label='Video URL*'/>*/}
            {/*        <FileDropzone onSuccess={file => {*/}
            {/*            change('imageUrl')(file);*/}
            {/*        }} accept={{'video/*': []}}/>*/}
            {/*    </Col>*/}
            {/*    <Col md={2}>*/}
            {/*        <AppInput type={InputType.SELECT} value={input.category} onChange={change('category')}*/}
            {/*                  label='Category*'>*/}
            {/*            {Object.entries(FeedPostCategory).map(([key, value]) => {*/}
            {/*                if (value === FeedPostCategory.TrendingNow) {*/}
            {/*                    return null;*/}
            {/*                }*/}
            {/*                return <option value={value} key={key}>{value}</option>*/}
            {/*            })}*/}
            {/*        </AppInput>*/}
            {/*    </Col>*/}
            {/*    <Col md={2}>*/}
            {/*        <AppInput type={InputType.TEXT} value={input.contentSource} onChange={change('contentSource')}*/}
            {/*                  label='Content Source*'/>*/}
            {/*    </Col>*/}
            {/*    <Col md={3}>*/}
            {/*        <AppInput type={InputType.TEXT} value={input.description} onChange={change('description')}*/}
            {/*                  label='Description*'/>*/}
            {/*    </Col>*/}
            {/*    <Col md={2}>*/}
            {/*        <AppInput type={InputType.SELECT} value={input.categoryId} onChange={(val) => change('categoryId')(Number.parseInt(val.toString()))}*/}
            {/*                  label='Drill Category'>*/}
            {/*            <option value={undefined} key={0}>---</option>*/}
            {/*            {catData?.categories.map(({id, title}) => {*/}
            {/*                return <option value={id} key={id}>{title}</option>*/}
            {/*            })}*/}
            {/*        </AppInput>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*<Button variant='success' style={{width: '100%'}} onClick={onSubmit}>Submit</Button>*/}
            <Modal show={showPushModal} onHide={() => {
                setShowPushModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Send push to users about video {pushFeedId}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AppInput
                        type={InputType.TEXT_AREA}
                        value={pushDescription}
                        onChange={(val: string) => {
                            setPushDescription(val);
                        }}
                        label='Text'
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        setShowPushModal(false)
                    }}>
                        CLOSE
                    </Button>
                    <Button onClick={handleSendPush}>SEND</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={successCreatedModal} onHide={() => {
                setSuccessCreatedModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Feed post created. Send push to users about video?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button onClick={() => {
                        setSuccessCreatedModal(false)
                        setPushFeedId(undefined)
                        setPushDescription('')
                    }}>
                        No
                    </Button>
                    <Button onClick={() => {
                        setSuccessCreatedModal(false)
                        setShowPushModal(true)
                    }}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
